<template>
  <div class="site-permission">
    <b-form-group
      label="Select site"
      label-for="select-site-dropdown"
      label-class="form-label required"
    >
      <b-dropdown
        id="select-site-dropdown"
        :text="selectedSiteText"
        ref="siteDropdown"
        menu-class="w-100"
        block
        no-caret
        lazy
        class="w-50"
        variant="brand-dropdown"
      >
        <b-dropdown-form @submit.prevent>
          <div class="form-group">
            <b-form-input
              type="search"
              class="nosubmit w-100"
              placeholder="Search"
              v-model="searchText"
            />
          </div>
          <div class="scrollable">
            <b-dropdown-item
              v-for="(site, index) in sitesList"
              :key="index"
              @click="form.siteId = site.value"
              :active="form.siteId == site.value"
            >
              {{ site.text }}
            </b-dropdown-item>
          </div>
        </b-dropdown-form>
      </b-dropdown>

      <b-form-invalid-feedback id="dropdown-form-feedback">
        {{ sytemMessages.siteAccessRequired }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-card class="site-permission">
      <b-alert v-model="showAlert" :variant="alertvariant" dismissible>{{
        alertMessage
      }}</b-alert>
      <div v-if="form.siteId != 0 || form.siteId != ''" class="row">
        <div class="col-sm-12 col-md-4">
          <label for="exampleFormControlInput1" class="form-label">{{
            "Pricing Manager"
          }}</label>
          <div class="d-flex">
            <label class="mr-4">
              <input
                type="radio"
                value="1"
                v-model="form.isPricingManagerAccess"
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                value="0"
                v-model="form.isPricingManagerAccess"
              />
              No
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <label class="form-label">{{ "Tag Manager" }}</label>
          <div class="d-flex">
            <label class="mr-4">
              <input type="radio" value="1" v-model="form.isTagManagerAccess" />
              Yes
            </label>
            <label>
              <input type="radio" value="0" v-model="form.isTagManagerAccess" />
              No
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <label class="form-label">{{ "Store Location" }}</label>
          <div class="d-flex">
            <label class="mr-4">
              <input
                type="radio"
                value="1"
                v-model="form.isStoreLocationAccess"
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                value="0"
                v-model="form.isStoreLocationAccess"
              />
              No
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <label class="form-label">{{ "Recycle Fee" }}</label>
          <div class="d-flex">
            <label class="mr-4">
              <input type="radio" value="1" v-model="form.isRecycleFeeAccess" />
              Yes
            </label>
            <label>
              <input type="radio" value="0" v-model="form.isRecycleFeeAccess" />
              No
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <label class="form-label">{{ "GqlToShopifyEnabled" }}</label>
          <div class="d-flex">
            <label class="mr-4">
              <input
                type="radio"
                value="1"
                v-model="form.isGqlToShopifyEnabled"
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                value="0"
                v-model="form.isGqlToShopifyEnabled"
              />
              No
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <label class="form-label">{{ "EcomToGqlEnabled" }}</label>
          <div class="d-flex">
            <label class="mr-4">
              <input type="radio" value="1" v-model="form.isEcomToGqlEnabled" />
              Yes
            </label>
            <label>
              <input type="radio" value="0" v-model="form.isEcomToGqlEnabled" />
              No
            </label>
          </div>
        </div>

        <div class="d-flex flex-row-reverse col-12">
          <b-button @click="handleSave" variant="brand-primary">Save</b-button>
        </div>
      </div>

      <div class="d-flex justify-content-center" v-else>
        <error-message
          message="Please select site to display the permission(s)"
        />
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import config from "../../../../../config";
import { forEach, orderBy } from "lodash";
import { required } from "vuelidate/lib/validators";
import ErrorMessage from "../../../../components/ErrorMessage.vue";
const { messages } = config;
export default {
  name: "SiteSetting",
  components: { ErrorMessage },
  data() {
    return {
      sitesList: [],
      searchText: "",
      showAlert: false,
      alertvariant: "",
      alertMessage: "",
      form: {
        siteId: 0,
        isPricingManagerAccess: 0,
        isTagManagerAccess: 0,
        isStoreLocationAccess: 0,
        isRecycleFeeAccess: 0,
        isGqlToShopifyEnabled: 0,
        isEcomToGqlEnabled: 0,
        userId: 0,
      },
    };
  },
  computed: {
    ...mapGetters("siteManager", {
      allSites: "storeList",
    }),
    selectedSiteText() {
      if (this.form.siteId == 0) return "Select";
      let selectedSite = this.allSites.find(
        (site) => site.storeId == this.form.siteId
      );

      return selectedSite.storeName;
    },
  },
  methods: {
    ...mapActions("siteManager", [
      "getStoreList",
      "getStorePermission",
      "updateStorePermission",
    ]),
    ...mapMutations("siteManager", ["setItem"]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    handleSave() {
      try {
        this.generalSetItem({ resource: "isLoading", payload: true });
        this.form.userId = this.loggedUserId;
        this.updateStorePermission(this.form)
          .then((response) => {
            this.showAlert = true;
            if (response == 1) {
              this.alertvariant = "success";
              this.alertMessage = messages.permissionUpdated;
            } else {
              this.alertvariant = "danger";
              this.alertMessage = messages.somethingwentWrong;
            }
            this.generalSetItem({ resource: "isLoading", payload: false });
          })
          .catch(() => {
            this.generalSetItem({ resource: "isLoading", payload: false });
          });
      } catch {
        this.generalSetItem({ resource: "isLoading", payload: false });
      }
    },
  },
  async beforeMount() {
    this.generalSetItem({ resource: "isLoading", payload: true });
    this.setItem({ resource: "searchQuery", payload: "" });
    this.getStoreList({ userId: this.loggedUserId })
      .then(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      })
      .catch(() => {
        this.generalSetItem({ resource: "isLoading", payload: false });
      });
  },
  watch: {
    "form.siteId": function () {
      this.generalSetItem({ resource: "isLoading", payload: true });
      // this.setItem({ resource: "userModules", payload: [] });
      // this.modules = this.userModules = [];
      this.form.isPricingManagerAccess = 0;
      this.form.isTagManagerAccess = 0;
      this.form.isStoreLocationAccess = 0;
      this.form.isRecycleFeeAccess = 0;
      this.form.isGqlToShopifyEnabled = 0;
      this.form.isEcomToGqlEnabled = 0;


      this.getStorePermission(this.form.siteId)
        .then((response) => {
          this.generalSetItem({ resource: "isLoading", payload: false });
          const {
            isPricingManagerAccess,
            isTagManagerAccess,
            isEcomToGqlEnabled,
            isGqlToShopifyEnabled,
            isRecycleFeeAccess,
            isStoreLocationAccess,
          } = response;
          this.form.isPricingManagerAccess = isPricingManagerAccess;
          this.form.isTagManagerAccess = isTagManagerAccess;
          this.form.isStoreLocationAccess = isStoreLocationAccess;
          this.form.isRecycleFeeAccess = isRecycleFeeAccess;
          this.form.isGqlToShopifyEnabled = isGqlToShopifyEnabled;
          this.form.isEcomToGqlEnabled = isEcomToGqlEnabled;
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    searchText(newValue) {
      if (this.allSites.length > 0) {
        let newOptions = [];
        forEach(this.allSites, (site) => {
          if (
            site.storeName.toLowerCase().includes(newValue.toLowerCase()) ||
            newValue == ""
          ) {
            let obj = {
              value: site.storeId,
              text: `${site.storeName}`,
            };
            newOptions.push(obj);
          }
        });
        this.sitesList = newOptions;
      }
    },
    allSites: {
      handler: function (newValue) {
        if (newValue.length) {
          let sites = [];
          forEach(newValue, (site) => {
            sites.push({
              value: site.storeId,
              text: `${site.storeName}`,
            });
          });

          const sortedSitess = orderBy(
            sites,
            [(site) => site.text.toLowerCase()],
            ["asc"]
          );
          this.sitesList = sortedSitess;
        } else {
          this.sitesList = [];
        }
      },
      deep: true,
    },
  },
  validations: {
    form: {
      siteId: { required },
    },
  },
};
</script>
